// Dark mode styles
.dark-mode {
  // Apply dark mode styles to the body and background
  background-color: #121212;
  color: #9bacc4;

  // Topnav styles for dark mode
  #topnav {
    background-color: #121212;

    .navigation-menu > li > a {
      color: #9bacc4;
    }

    .navigation-menu > li:hover > a {
      color: #2f55d4;
    }

    .navigation-menu > li .submenu li a {
      color: #9bacc4;
    }

    .navigation-menu > li .submenu li a:hover {
      color: #2f55d4;
    }

    .buy-button .btn {
      color: #ffffff;
    }

    .logo {
      .l-dark,
      .logo-light-mode {
        display: none !important;
      }
      .l-light,
      .logo-dark-mode {
        display: inline-block !important;
      }
    }
  }

  // Card styles
  .card {
    background-color: #1e1e1e;
    border-color: #2d2d2d;
  }

  // Course card styles - fix for dark mode
  .blog.blog-primary {
    .content {
      .title.text-dark {
        color: #ffffff !important; // Make course titles white for better visibility
      }

      .numbers, .rating {
        color: #9bacc4; // Lighter color for other text
      }

      h4, h5, h6 {
        color: #ffffff; // Make headings white
      }
    }

    .preview {
      color: #ffffff !important; // Make "Preview Now" text white
    }
  }

  // Popover styles for course cards
  .popover {
    background-color: #1e1e1e;
    border-color: #2d2d2d;

    .popover-body {
      color: #9bacc4;

      h4 {
        color: #ffffff;
      }

      p {
        color: #9bacc4;
      }

      .text-warning, .text-primary, .text-danger {
        color: inherit !important;
      }

      .text-secondary {
        color: #9bacc4 !important;
      }

      .course-popover {
        strong {
          color: #ffffff;
        }

        .badge {
          background-color: #f17425 !important;
          color: #ffffff;
        }

        ul li {
          color: #9bacc4;
        }
      }
    }

    // Popover arrow
    .popover-arrow::before {
      border-right-color: #2d2d2d;
    }

    .popover-arrow::after {
      border-right-color: #1e1e1e;
    }
  }

  // Form styles
  .form-control {
    background-color: #2d2d2d;
    border-color: #3d3d3d;
    color: #9bacc4;

    &:focus {
      border-color: #2f55d4;
    }
  }

  // Button styles
  .btn-light {
    background-color: #2d2d2d;
    border-color: #2d2d2d;
    color: #ffffff;
  }

  // Modal styles
  .modal-content {
    background-color: #1e1e1e;
    border-color: #2d2d2d;
  }

  // Dropdown styles
  .dropdown-menu {
    background-color: #1e1e1e;
    border-color: #2d2d2d;

    .dropdown-item {
      color: #9bacc4;

      &:hover, &:focus {
        background-color: rgba(47, 85, 212, 0.1);
        color: #2f55d4;
      }
    }
  }

  // Privacy and Terms Modal styles
  .modal {
    .modal-body {
      background-color: #1e1e1e;
      color: #9bacc4;

      h2, h3 {
        color: #ffffff;
      }

      p, li {
        color: #9bacc4;
      }

      strong {
        color: #ffffff;
      }

      .btn-soft-primary {
        background-color: rgba(47, 85, 212, 0.1);
        color: #2f55d4;

        &:hover {
          background-color: #2f55d4;
          color: #ffffff;
        }
      }

      section {
        border-color: #2d2d2d;
      }

      ul {
        li {
          strong {
            color: #ffffff;
          }
        }
      }
    }
  }

  // Cart dropdown styles
  .dd-menu.dropdown-menu-end {
    background-color: #1e1e1e !important;
    border-color: #2d2d2d !important;

    .text-dark {
      color: #ffffff !important;
    }

    .border-top {
      border-color: #2d2d2d !important;
    }

    a.d-flex {
      &:hover {
        background-color: rgba(47, 85, 212, 0.1);
      }
    }

    .btn-primary {
      background-color: #2f55d4;
      border-color: #2f55d4;
    }

    .dropdown-item {
      background-color: #1e1e1e;

      &:hover {
        background-color: #2d2d2d;
      }
    }
  }

  // RightSidebar cart styles
  .offcanvas {
    background-color: #1e1e1e;
    border-color: #2d2d2d;

    h4 {
      color: #ffffff;
    }

    p {
      color: #9bacc4;
    }

    .card {
      background-color: #2d2d2d;
      border-color: #3d3d3d;

      .card-title {
        color: #ffffff;
      }

      .card-text {
        color: #9bacc4;
      }
    }

    .btn-primary {
      background-color: #2f55d4;
      border-color: #2f55d4;
    }

    .btn-outline-primary {
      color: #2f55d4;
      border-color: #2f55d4;

      &:hover {
        background-color: #2f55d4;
        color: #ffffff;
      }
    }
  }

  // Empty cart modal
  #empty-cart {
    .modal-body {
      background-color: #1e1e1e;

      h4 {
        color: #ffffff;
      }

      p {
        color: #9bacc4;
      }
    }
  }
}

// Theme toggle button styles
.btn-icon {
  width: 38px;
  height: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;

  i {
    font-size: 1.25rem;
    transition: all 0.3s ease;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

// Animation for theme toggle
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dark-mode .btn-icon i.mdi-weather-sunny {
  animation: spin 0.5s ease-out;
}

.btn-icon i.mdi-weather-night {
  animation: spin 0.5s ease-out;
}